import React from "react";
import {
  SEO,
  PageLayout,
  SocialMediaV2,
  MultipleComponentIterator,
  OutboundLink,
  InternalLink,
  Image,
  FadeReveal,
  MarkdownContent,
  ServicesList,
  BasicCarousel,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  HoursOfOperation,
  Location,
  ContactForm,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuSchedule,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Grid
            className={"default-hero"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={8} className={"hero-heading"}>
              <Header
                as={"h1"}
                className={""}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "home_header_text",
                })}
              />
              <SocialMediaV2 className={"social-media-container"}>
                <MultipleComponentIterator
                  iterator={fishermanBusiness.socialMedia}
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                >
                  {bookingUrl.title}
                </Button>
                <Button basic={true} to={"#image-gallery"} as={InternalLink}>
                  See Gallery
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column width={8} className={"hero-image"}>
              <Image
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            id={"about"}
            className={"component-section-container about-section"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={8}>
              <FadeReveal duration={1250} triggerOnce={true}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header_text",
                    defaultValue: "About",
                  })}
                />
                <MarkdownContent
                  className={"about-section-description"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_description_markdown",
                  })}
                />
                <Button
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  primary={true}
                >
                  Book Now
                </Button>
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <div
            className={"component-section-container services-section"}
            id={"services"}
          >
            <Header
              as={"h2"}
              children={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_header_text",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "services_description_markdown",
              })}
            />
            <div className={"services-list-container"}>
              <FadeReveal duration={1250} triggerOnce={true}>
                <Image
                  className={"services-section-image"}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentIdentifier: "services_section_image",
                    numToSelect: 1,
                  })}
                />
              </FadeReveal>
              <Button
                as={OutboundLink}
                to={bookingUrl.url}
                event={{
                  category: "Booking",
                  action: "Schedule Appointment Intent",
                }}
                primary={true}
                fluid={true}
              >
                Schedule An Appointment
              </Button>
              <ServicesList
                defaultAllOpen={false}
                priceDisplayType={"range"}
                catalog={{
                  schedules: allFishermanBusinessMenuSchedule.nodes,
                  categories: allFishermanBusinessMenuCategory.nodes,
                  items: allFishermanBusinessMenuItem.nodes,
                }}
                animated={true}
              />
            </div>
          </div>
          <div className={"reviews-section component-section-container"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_header",
                defaultValue: "What Customers Are Saying",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_description",
              })}
            />
            <BasicCarousel
              className={"reviews-carousel faded-sides-carousel"}
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              leftArrowIcon={"arrow-left"}
              rightArrowIcon={"arrow-right"}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <ReviewV2.Text
                            charLimit={460}
                            seeMoreIcon={"plus"}
                            seeLessIcon={"minus"}
                          />
                        ),
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
              />
            </div>
          </div>
          <div
            className={"image-gallery-section component-section-container"}
            id={"image-gallery"}
          >
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_header_text",
                defaultValue: "Color",
              })}
            />
            <BasicCarousel
              className={"image-gallery simple-carousel"}
              size={4}
              grouping={2}
              displayIndicators={true}
              displayPlayPauseButton={true}
              autoScroll={true}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={multipleComponentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                components: [
                  {
                    component: (
                      <Image
                        background={true}
                        className={"gallery-image-item"}
                      />
                    ),
                    propMap: { key: "_id", src: "__all__" },
                  },
                ],
              })}
            />
          </div>
          <div className={"component-section-container location-section"}>
            <div className={"location-header-container"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "location_section_header",
                  defaultValue: "Visit us",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "location_section_description",
                })}
              />
              <SocialMediaV2>
                <MultipleComponentIterator
                  iterator={fishermanBusiness.socialMedia}
                  components={[
                    {
                      component: <SocialMediaV2.Platform />,
                      propMap: { key: "_id", url: "link", platform: "type" },
                      children: [
                        {
                          component: <SocialMediaV2.Icon />,
                          propMap: { icon: "type" },
                        },
                      ],
                    },
                  ]}
                />
              </SocialMediaV2>
            </div>
            <Grid
              stackable={true}
              verticalAlign={"middle"}
              textAlign={"center"}
              centered={true}
              column={2}
            >
              <Grid.Row stretched={true}>
                <Grid.Column width={8} textAlign={"center"}>
                  <FadeReveal duration={1250} triggerOnce={true}>
                    <Header as={"h3"} content={"Hours"} />
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </FadeReveal>
                </Grid.Column>
                <Grid.Column width={8} textAlign={"center"}>
                  <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                    <Header as={"h3"} content={"Address"} />
                    <Location
                      businessName={fishermanBusiness.name}
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        city: fishermanBusiness.primaryLocation.city,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                      }}
                    />
                  </FadeReveal>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          <div className={"component-section-container contact-section"}>
            <div className={"contact-header-container"}>
              <Header
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "contact_header_text",
                  defaultValue: "Contact Us",
                })}
              />
            </div>
            <div className={"contact-form-container"}>
              <ContactForm
                buttonText={"Submit"}
                centeredHeaders={false}
                hideOptIn={false}
                className={""}
                fluidButton={false}
                header={
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "contact_form_description_markdown",
                    })}
                  />
                }
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={false}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
                event={{ category: "Contact", action: "Submit Inquiry" }}
              />
            </div>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
      primaryLocation {
        hours {
          day
          open
          close
          label
        }
        street
        city
        state
        zipCode
        _id
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          _id
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuSchedule {
      nodes {
        categories
      }
    }
    allFishermanBusinessMenuCategory(sort: { order: ASC, fields: order }) {
      nodes {
        items
        description
        name
        _id
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
    allFishermanBusinessMenuItem(
      sort: { order: ASC, fields: order }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        modifierSets
        description
        _id
        name
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        variations {
          _id
          menuItem
          name
          order
          price
        }
        annotations {
          likeCount
        }
        visible
        available
        interactions {
          _id
          behaviorType
          displayType
          enabled
          url
          style
          label
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
